import * as R from 'ramda';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// common
import { makeSelectOrderTable } from '../common/idb/order/selectors';
import { getReportTableOrder, updateTableOrderByGuid } from '../common/idb/order/actions';
// components
import { openModal, closeModal } from '../components/modal/actions';
// helpers/constants
import * as G from '../helpers';
// icons
import * as I from '../svgs';
// ui
import { Box, Flex } from '../ui';
// component edit-view
import { EditViewForm } from '../components/';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.darkBlue');

const useEditViewHook = (props: Object) => {
  const {
    title,
    report,
    boxStyles,
    titleStyles,
    columnSettings,
    disableFieldHandler,
  } = props;

  const dispatch = useDispatch();

  const fieldsTable = useSelector(makeSelectOrderTable());

  const reportGuid = G.getGuidFromObject(report);

  useEffect(() => {
    dispatch(getReportTableOrder(reportGuid));
  }, [reportGuid]);


  const uiReportFields = R.propOr([], reportGuid, fieldsTable);

  const setUiReportFields = useCallback((reportFields: Array) => {
    const { guid } = report;

    dispatch(updateTableOrderByGuid({
      reportGuid: guid,
      orderTable: reportFields,
    }));
  }, [report]);

  const resetUiReportFields = useCallback(() => {
    const { guid } = report;

    dispatch(updateTableOrderByGuid({
      orderTable: [],
      reportGuid: guid,
    }));

    dispatch(closeModal());
  }, [report]);

  const handleOpenEditView = useCallback(() => {
    const component = (
      <EditViewForm
        reportGuid={reportGuid}
        columnSettings={columnSettings}
        uiReportFields={uiReportFields}
        setUiReportFields={setUiReportFields}
        resetUiReportFields={resetUiReportFields}
        disableFieldHandler={disableFieldHandler}
        {...R.pick([
          'closeModal',
          'defaultReportFields',
        ], props)}
      />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        width: 500,
        top: '0px',
        right: '0px',
        movable: false,
        height: '100vh',
        componentWrapperHeight: '100%',
        title: G.getWindowLocale('titles:fields', 'Fields'),
      },
    };

    dispatch(openModal(modal));
  }, [
    columnSettings,
    uiReportFields,
    setUiReportFields,
    disableFieldHandler,
    resetUiReportFields,
  ]);

  const editViewBtnComponent = (
    <Flex
      ml={20}
      gap='5px'
      cursor='pointer'
      onClick={handleOpenEditView}
      {...G.spreadUiStyles(boxStyles)}
    >
      <Flex>{I.gear(darkBlueColor)}</Flex>
      <Box fontSize={12} color={darkBlueColor} {...G.spreadUiStyles(titleStyles)}>
        {R.or(title, G.getWindowLocale('titles:edit-view', 'Edit View'))}
      </Box>
    </Flex>
  );

  return {
    uiReportFields,
    handleOpenEditView,
    editViewBtnComponent,
  };
};

export default useEditViewHook;
