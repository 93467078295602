import * as R from 'ramda';
import { useState } from 'react';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

const useUIFilters = ({
  report,
  itemList,
  filterProps,
  shouldUseUIFilters,
}: Object) => {
  const [uiFilters, setUIFilters] = useState({});
  const [uiSorting, setUISorting] = useState({});

  let filteredItemList = itemList;

  if (R.and(shouldUseUIFilters, G.isNotNilAndNotEmpty(uiFilters))) {
    filteredItemList = G.getItemListFilteredByUiTableTitleFilters(
      report,
      itemList,
      uiFilters,
    );
  }

  if (R.and(shouldUseUIFilters, G.isNotNilAndNotEmpty(uiSorting))) {
    filteredItemList = G.getItemListSortedByUITableSorting(
      uiSorting,
      filterProps,
      filteredItemList,
    );
  }

  return {
    uiFilters,
    uiSorting,
    setUIFilters,
    setUISorting,
    filteredItemList,
  };
};

export default useUIFilters;
