import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
import { isProduction } from './env';
import { createStringFromArray } from './string';
import { getAmousCurrentUserFromWindow, getAmousCurrentUserGuidFromWindow } from './window';
import { ifElse, notContain, isNotEmpty, isNilOrEmpty, isNotNilAndNotEmpty } from './helpers';
//////////////////////////////////////////////////

// NOTE: for testing on prod only for amous testers
const isProdAndUserNotAmousTester = () => {
  const userGuid = getAmousCurrentUserGuidFromWindow();

  return R.and(
    isProduction,
    notContain(userGuid, ['1ddc9ceb-7725-4385-96ab-2f14147a0f40', '39a435fa-53c1-4c95-86e3-038c83777c87']),
  );
};

const getUserLoginIdOrNames = (user: Object) => {
  if (isNilOrEmpty(user)) return '';

  const loginId = R.path([GC.FIELD_USER_LOGIN_ID], user);

  if (isNotNilAndNotEmpty(loginId)) return loginId;

  return createStringFromArray(R.values(R.pick([GC.FIELD_USER_FIRST_NAME, GC.FIELD_USER_LAST_NAME], user)));
};

export const getUserInfo = (user: Object) => {
  if (isNilOrEmpty(user)) {
    return {
      loginId: null,
      fullText: null,
      avatarText: null,
      firstLastNames: null,
      firstLastMiddleNames: null,
    };
  }

  const loginId = R.pathOr('', [GC.FIELD_USER_LOGIN_ID], user);

  let fullText = loginId;

  const lastName = R.pathOr('', [GC.FIELD_USER_LAST_NAME], user);
  const firstName = R.pathOr('', [GC.FIELD_USER_FIRST_NAME], user);

  let avatarText = R.toUpper(R.pathOr('', [0], fullText));

  const firstLastMiddleNames = createStringFromArray(
    R.values(R.pick([GC.FIELD_USER_FIRST_NAME, GC.FIELD_MIDDLE_NAME, GC.FIELD_USER_LAST_NAME], user)),
  );

  if (R.or(isNotEmpty(firstName), isNotEmpty(lastName))) {
    fullText = ifElse(
      isNotNilAndNotEmpty(fullText),
      `${firstLastMiddleNames} (${fullText})`,
      firstLastMiddleNames,
    );

    avatarText = `${R.toUpper(R.pathOr('', [0], firstName))}${R.toUpper(R.pathOr('', [0], lastName))}`;
  }

  return {
    loginId,
    fullText,
    avatarText,
    firstLastMiddleNames,
    firstLastNames: createStringFromArray(R.values(R.pick([GC.FIELD_USER_FIRST_NAME, GC.FIELD_USER_LAST_NAME], user))),
  };
};

export const isCurrentUserMessage = ({ authorUserGuid }: Object) => {
  const { user_guid } = getAmousCurrentUserFromWindow();

  return R.equals(user_guid, authorUserGuid);
};

export {
  getUserLoginIdOrNames,
  isProdAndUserNotAmousTester,
};
