import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
import { compose, lifecycle } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
//////////////////////////////////////////////////

export class InitialData extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { data: null, error: false, loading: true };
    this.setLoading = this.setLoading.bind(this);
    this.getInitialDataRequest = this.getInitialDataRequest.bind(this);
  }

  setLoading() {
    this.setState(R.assoc('loading', true, this.state));
  }

  async getInitialDataRequest(endpoint: string | null, requestOptions: Object | null) {
    const {
      showMsg,
      closeModal,
      openLoader,
      showDimmer,
      closeLoader,
      asyncMethod,
      asyncOptions,
      asyncEndpoint,
      asyncShouldCloseModal,
    } = this.props;

    if (G.isFunction(openLoader)) {
      openLoader({ showDimmer: G.ifElse(G.isNotNilAndNotEmpty(showDimmer), showDimmer, true)});
    }
    const options = R.or(requestOptions, asyncOptions);

    const res = await sendRequest(R.or(asyncMethod, 'get'), R.or(endpoint, asyncEndpoint), options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNil(data)) {
        const newState = P.$all(
          P.$set('data', data),
          P.$set('loading', false),
          P.$set('dataUniqId', G.generateGuid2()),
          this.state,
        );
        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        R.or(showMsg, false),
        'withAsyncInitialData -> getInitialDataRequest',
      );
    }

    if (G.isFunction(closeLoader)) closeLoader();

    if (R.and(G.isTrue(asyncShouldCloseModal), G.isFunction(closeModal))) closeModal();
  }
  render() {
    return <div>{this.props.render(this.state, this.getInitialDataRequest, this.setLoading)}</div>;
  }
}

export const withAsyncInitialData = (Component: any) => (
  class extends React.Component {
    render() {
      return (
        <InitialData
          showMsg={this.props.showMsg}
          showDimmer={this.props.showDimmer}
          openLoader={this.props.openLoader}
          closeModal={this.props.closeModal}
          closeLoader={this.props.closeLoader}
          asyncOptions={this.props.asyncOptions}
          asyncEndpoint={this.props.asyncEndpoint}
          asyncMethod={R.or(this.props.asyncMethod, 'get')}
          asyncShouldCloseModal={this.props.asyncShouldCloseModal}
          render={(parentState: Object, getInitialDataRequest: Function, setLoading: Function) =>
            <Component
              {...this.props}
              setLoading={setLoading}
              asyncInitialData={parentState}
              getInitialDataRequest={getInitialDataRequest} />
          } />
      );
    }
  }
);

export const withAsyncInitialDataOnDidMount = compose(
  withAsyncInitialData,
  lifecycle({
    componentDidMount() {
      this.props.getInitialDataRequest();
    },
  }),
);
