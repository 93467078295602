import * as R from 'ramda';
import shortid from 'shortid';
import { v4 as uuidv4 } from 'uuid';
//////////////////////////////////////////////////

const genShortId = () => (
  shortid.generate()
);

// TODO: test it, maybe replace 'shortid' lib
// Quick version
// When n = 1000 there is a chance 1 to 200 millions to get the same id
const generateShortId = () => Math.random().toString(36).substr(2, 9);

const generateGuid = () => uuidv4();

// TODO: test it, maybe replace 'uuid' lib
// eslint-disable-next-line no-undef
const generateGuid2 = () => crypto.randomUUID();

const setGuidToArrayObjectItems = (arr: Array = []) => R.map(
  (item: Object = {}) => R.assoc('guid', generateGuid(), item),
  arr,
);

export {
  genShortId,
  generateGuid,
  generateGuid2,
  generateShortId,
  setGuidToArrayObjectItems,
};
