import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
import { quickClone } from './common';
import { isNotNilAndNotEmpty } from './helpers';
import { setItemToWindow, getItemFromWindow, getBranchNameFromWindowByGuid } from './window';
//////////////////////////////////////////////////

// helper for set custom prop with boolean value to every item in tree
function setBoolPropToEveryTreeItem(tree: Object, status: boolean, prop: string) {
  const newTree = quickClone(tree);

  if (R.not(R.isEmpty(newTree.children))) {
    newTree.children = newTree.children.map(
      (childrenTree: Object) => setBoolPropToEveryTreeItem(childrenTree, status, prop),
    );
  }

  return R.assoc(prop, status, newTree);
}

// Not used
// helper for change current item boolean prop in tree
// function changeBoolPropCurrentTreeItem(
//   tree: Object,
//   boolProp: string,
//   guids: Array = null,
//   savePrevValue: boolean = false,
// ) {
//   const newTree = R.clone(tree);

//   if (R.not(R.isEmpty(tree.children))) {
//     newTree.children = newTree.children.map(
//       (childrenTree: Object) => changeBoolPropCurrentTreeItem(childrenTree, boolProp, guids, savePrevValue),
//     );
//   }

//   const status = savePrevValue ? newTree[boolProp] : false;

//   if (isNotNil(guids) && R.includes(newTree.guid, guids)) {
//     return R.assoc(boolProp, R.not(newTree[boolProp]), newTree);
//   }

//   return R.assoc(boolProp, status, newTree);
// }

// Not used
// helper for cut tree from current branch
// function cutTreeFromCurrentBranch(
//   tree: Object,
//   boolProp: string,
//   guids: Array = null,
//   callback: Function,
//   savePrevValue: boolean = false,
//   isCurrentChild: boolean = true,
//   currentEnt: string = null,
// ) {
//   let resultTree = R.clone(tree);
//   if (isNotNil(currentEnt) && R.not(isCurrentChild)) {
//     if (R.equals(resultTree.guid, currentEnt)) {
//       resultTree = cutTreeFromCurrentBranch(resultTree, boolProp, guids, callback, savePrevValue);
//     } else {
//       let findedCurrentEntTree = null;

//       if (R.not(R.isEmpty(tree.children))) {
//         resultTree.children.forEach(
//           (childrenTree: Object) => {
//             const childTreeResult = cutTreeFromCurrentBranch(
//               childrenTree,
//               boolProp,
//               guids,
//               callback,
//               savePrevValue,
//               isCurrentChild,
//               currentEnt);
//             findedCurrentEntTree = childTreeResult || findedCurrentEntTree;
//           },
//         );
//       }
//       resultTree = findedCurrentEntTree;
//     }

//     return resultTree;
//   }

//   return callback(
//     resultTree,
//     boolProp,
//     guids,
//     savePrevValue,
//   );
// }

const getBranchFromTreeByGuid = (branchGuid: string, tree: Object) => {
  if (R.propEq(branchGuid, GC.FIELD_GUID, tree)) {
    return tree;
  } else if (isNotNilAndNotEmpty(tree.children)) {
    let i;
    let result = null;

    for (i = 0; result == null && i < tree.children.length; i++) {
      result = getBranchFromTreeByGuid(branchGuid, tree.children[i]);
    }

    if (isNotNilAndNotEmpty(result)) return result;
  }
};

const getAmousBranchesObjectAndSetToGlobalWindow = (tree: Object) => {
  const amousBranches = {};

  const setBranch = (branch: Object) => {
    //! Don't replace this code with Ramda due to performance issues
    const children = branch.children;

    // eslint-disable-next-line no-param-reassign
    delete branch.children;

    const guid = branch[GC.FIELD_GUID];

    amousBranches[guid] = branch;

    if (children) {
      children.forEach((child: Object) => setBranch(child));
    }
  };

  const newTree = quickClone(tree);

  setBranch(newTree);

  setItemToWindow('amousBranches', amousBranches);
};

const createBranchOptionsFromGuids = R.map((guid: string) => ({
  value: guid,
  label: getBranchNameFromWindowByGuid(guid),
}));

const createBranchOptionsFromAmousBranches = () => R.compose(
  R.map(({ guid, enterpriseName }: Object) => ({
    value: guid,
    label: enterpriseName,
  })),
  R.values,
)(getItemFromWindow('amousBranches'));

export {
  getBranchFromTreeByGuid,
  // cutTreeFromCurrentBranch,
  setBoolPropToEveryTreeItem,
  createBranchOptionsFromGuids,
  // changeBoolPropCurrentTreeItem,
  createBranchOptionsFromAmousBranches,
  getAmousBranchesObjectAndSetToGlobalWindow,
};
