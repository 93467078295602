import * as R from 'ramda';
import { useState, useCallback } from 'react';
// helpers/constants
import * as G from '../helpers';
// utilities
import { sendRequestWithQSParamsSerializer } from '../utilities/http';
// hooks
import { useLoaderActions } from './';
//////////////////////////////////////////////////

const useDownloadAndPreviewDocument = () => {
  const { openLoader, closeLoader } = useLoaderActions();

  const [documentURL, setDocumentURL] = useState(null);

  const [documentRes, setDocumentRes] = useState(null);

  const handleDownloadOrPreviewDocument = useCallback(async (props: Object) => {
    const { params, endpoint, actionType, returnObjectURL, documentFilename } = props;

    openLoader();

    const options = {
      params,
      resType: 'arraybuffer',
    };

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      setDocumentRes(res);

      if (R.equals(actionType, 'download')) {
        G.saveFileFromResponse(res, documentFilename);
      } else if (G.isTrue(returnObjectURL)) {
        setDocumentURL(G.getImageSrcFromArrayBufferResponse(res));
      } else {
        G.openFileInWindowFromArrayBufferResponse(res, { defaultFileName: documentFilename });
      }
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, []);

  return {
    documentRes,
    documentURL,
    setDocumentURL,
    handleDownloadOrPreviewDocument,
  };
};

export {
  useDownloadAndPreviewDocument,
};
